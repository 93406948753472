import type { BillingAccountInvoice } from "@warrenio/api-spec/spec.oats.gen";
import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import type { ReactNode } from "react";
import { ContentPane } from "../../components/ContentPane.tsx";
import { CurrencyBalance, CurrencyHourly } from "../../components/l10n/Currency.tsx";
import { Separator } from "../../components/Separator.tsx";
import { Spacer } from "../../components/Spacer.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import TC from "../../components/table/Table.module.css";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { ViewTitle } from "../../components/Title.tsx";
import { InvoiceDueDate, InvoiceIssueDate, InvoicePeriod } from "../../modules/billing/invoice/InvoiceDates.tsx";
import { ReportStatus } from "../../modules/billing/ReportStatus.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { invoiceQueryAtom } from "./reportsQuery.ts";
import type { GQInvoiceItem } from "./ReportsTable.tsx";

interface AdminBillingAccount {
    allow_debt: boolean;
    email: string;
    id: string;
    title?: string;
    __typename?: string;
}

interface AdminBillingAccountInvoice extends BillingAccountInvoice {
    billing_account: AdminBillingAccount;
}

export function InvoiceDetailView({ item }: { item: GQInvoiceItem }) {
    const data: BillingAccountInvoice = useSuspenseQueryAtom(invoiceQueryAtom(item.id));
    const invoice: AdminBillingAccountInvoice = {
        ...data,
        billing_account: item.billing_account as AdminBillingAccount,
    };

    return (
        <>
            <InvoiceDetails item={invoice} />
            <Separator />
            <ServicesContent item={invoice} />
        </>
    );
}

function getIsReport({ type, billing_account }: AdminBillingAccountInvoice) {
    return !billing_account?.allow_debt && type === "cloud_services";
}

interface SummaryField {
    title: ReactNode;
    value: ReactNode;
}

function getSummaryFields(item: AdminBillingAccountInvoice): SummaryField[] {
    const { totals, discount_percentage, vat_percentage } = item;

    const subtotal: SummaryField = { title: "Subtotal", value: <CurrencyBalance value={totals?.subtotal ?? 0} /> };

    const isReport = getIsReport(item);
    if (isReport) {
        return [subtotal];
    }

    return filterFalse<SummaryField>([
        subtotal,
        !!discount_percentage && {
            title: `Discount ${discount_percentage}%`,
            value: <CurrencyBalance value={totals?.discount_amount ?? 0} />,
        },
        !!totals?.credit && {
            title: "Credit",
            value: <CurrencyBalance value={totals?.credit ?? 0} />,
        },
        {
            title: "Total excl. VAT",
            value: <CurrencyBalance value={totals?.total_before_tax ?? 0} />,
        },
        {
            title: `VAT (${vat_percentage}%)`,
            value: <CurrencyBalance value={totals?.vat_tax ?? 0} />,
        },
        {
            title: "Total",
            value: <CurrencyBalance value={(totals?.total_before_tax ?? 0) + (totals?.vat_tax ?? 0)} />,
        },
    ]);
}

function InvoiceDetails({ item }: { item: AdminBillingAccountInvoice }) {
    const { status, padded_id, type, billing_account } = item;

    const { title, allow_debt } = billing_account;
    const isReport = getIsReport(item);

    return (
        <ContentPane className="VStack gap-4">
            <ViewTitle
                title={`Report #${padded_id}`}
                // TODO: Remove global margins from all headings and instead use container-based layouts (eg. gap)
                // (otherwise it's impossible to lay out optional (nullable) components nicely without passing additional margin properties into every one of them)
                className="important:pb-0 important:min-h-auto"
            />

            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableRow title="Report ID:">{`#${padded_id}`}</DetailsTableRow>

                    {(allow_debt || type === "credit_topup") && (
                        <DetailsTableRow title="Issue date:">
                            <InvoiceIssueDate invoice={item} />
                        </DetailsTableRow>
                    )}

                    {(allow_debt || type === "credit_topup") && (
                        <DetailsTableRow title="Due date:">
                            <InvoiceDueDate invoice={item} />
                        </DetailsTableRow>
                    )}

                    {padded_id && type !== "credit_topup" && (
                        <DetailsTableRow title="Period:">
                            <InvoicePeriod invoice={item} />
                        </DetailsTableRow>
                    )}

                    {!isReport && (
                        <DetailsTableRow title="Status:">
                            <ReportStatus value={status ?? 0} />
                        </DetailsTableRow>
                    )}

                    <DetailsTableRow title="Billing account:">{title}</DetailsTableRow>
                </DetailsTable>

                <Spacer />

                <DetailsTable>
                    {getSummaryFields(item).map(({ title, value }, index) => (
                        <DetailsTableRow key={index} title={title}>
                            {value}
                        </DetailsTableRow>
                    ))}
                </DetailsTable>
            </DetailsHolder>
        </ContentPane>
    );
}

function ServicesContent({ item }: { item: AdminBillingAccountInvoice }) {
    const { records_list } = item;

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Services</h2>

            <WTable>
                <thead>
                    <tr>
                        <th>Service</th>
                        <th>Qty</th>
                        <th className="text-right">Unit</th>
                        <th className="text-right">Amount</th>
                    </tr>
                </thead>
                <WTableBody>
                    {records_list?.map((item) => {
                        const { id, item_price, name, qty, qty_unit, amount } = item;

                        return (
                            <tr key={id}>
                                <td>{name}</td>
                                <td>
                                    {qty} {qty_unit}
                                </td>
                                <td className="text-right">
                                    <CurrencyHourly value={item_price} /> / {qty_unit}
                                </td>
                                <td className="text-right">
                                    <CurrencyBalance value={amount} />
                                </td>
                            </tr>
                        );
                    })}
                </WTableBody>
                <tfoot className={TC.Clean}>
                    {getSummaryFields(item).map(({ title, value }, index) => (
                        <tr key={index}>
                            <td />
                            <td />
                            <td className="text-right">{title}</td>
                            <td className="text-right">{value}</td>
                        </tr>
                    ))}
                </tfoot>
            </WTable>
        </ContentPane>
    );
}
