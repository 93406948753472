import { notNull } from "@warrenio/utils/notNull";
import { Toolbar } from "../../components/Toolbar.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { useApiClient } from "../../modules/api/apiClient.store.ts";
import { downloadBlob } from "../../utils/downloadBlob.tsx";
import type { GQInvoiceItem } from "./ReportsTable.tsx";

export function InvoiceToolbar({ item }: { item: GQInvoiceItem }) {
    const api = useApiClient();

    const { padded_id, id } = item;
    const isReport = !item?.billing_account?.allow_debt && item?.type === "cloud_services";

    function getPdfName() {
        const prefix = isReport ? "Usage Report" : "Invoice";
        return `${prefix} ${padded_id}.pdf`;
    }

    async function downloadPdf() {
        const response = await api.GET("/payment/admin/invoice/pdf", {
            params: { query: { invoice_id: Number(id) } },
            parseAs: "blob",
        });

        downloadBlob(notNull(response.data, "PDF data"), getPdfName());
    }

    return (
        <Toolbar>
            {!!padded_id && (
                <WButton color="primary" size="bar" variant="ghost" action={downloadPdf} icon="jp-icon-download">
                    Download PDF
                </WButton>
            )}
        </Toolbar>
    );
}
