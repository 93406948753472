import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type { BillingAccountInvoice } from "@warrenio/api-spec/spec.oats.gen";
import { atomFamily } from "jotai/utils";
import { type ApiClient, getResponseData } from "../../modules/api/apiClient.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export interface Params {
    invoice_id: string;
}

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["payment/admin/invoices"] : ["payment/admin/invoices", params.invoice_id];
}

export function getSingleQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<BillingAccountInvoice> => {
            const { invoice_id } = params;
            return getResponseData(
                await apiClient.GET("/payment/admin/invoices/{invoice_id}", {
                    signal,
                    params: { path: { invoice_id } },
                }),
            );
        },
    });
}

export const invoiceQueryAtom = atomFamily((invoice_id: string) =>
    atomFromStandardQueryOptions(getSingleQuery, { invoice_id }),
);
